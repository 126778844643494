<template>
  <loginForm type="register" :pageList="pageList" />
</template>

<script>
import loginForm from '@/components/loginForm'

export default {
  name: 'Register',
  components: {
    loginForm
  },
  data () {
    return {
      pageList: [
        {
          name: 'Daftar',
          activate: true
        }
      ]
    }
  }
}
</script>
